import React from "react"
import { graphql } from "gatsby"
//import Author from "../components/author"
import BlocksRenderer from "../components/blocks-renderer"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
//import Headings from "../components/headings"
import Hero from "../components/hero"
import Headings from "../components/headings"
//import ShareButtons from "../components/share-buttons"

const ServicePage = ({ data, pageContext }) => {
  //console.log("Data:", data)
  //console.log("pageContext: ", pageContext.slug)

  //const articleBlocks = data.strapiArticle.blocks
  //console.log("articleBlocks:", articleBlocks)
  

  const service = data.strapiService
  
  //console.log("Service:", service)

  const structuredData = service.seo.structuredData
  //console.log("Service - structuredData:", structuredData)

  const imageUrl = `${service.seo.metaImage.localFile.publicURL}`
  //console.log("Service - imageUrl:", imageUrl)

  

  return (
    <div as="website">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={service.seo.metaTitle}
        description={service.seo.metaDescription}
        image={imageUrl}
        post={false}
        //structuredData={service.seo.structuredData}
        metaRobots={service.seo.metaRobots}
        metaSocial={service.seo.metaSocial}
      />

      <Hero hero={service.hero} />

      <div  className="uk-container" >
        <Headings
          title={service.headings.title}
          description={service.headings.description}
        />    
      </div>
      <BlocksRenderer blocks={service.blocks || []} />
    </div>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiService(slug: { eq: $slug }) {
      id
      slug
      hero {
        ...StrapiHero
      }
      headings {
        title
        description
      }
      blocks {
        ...Blocks
      }
      seo {
        ...StrapiSeo
        metaSocial {
          socialNetwork
          title
          description
        }
        structuredData {
          _type
          name
          url
          contactPoint {
            _type
            availableLanguage
            contactType
            telephone
          }
          sameAs
        }
      } 
    }
  }
`

export default ServicePage
